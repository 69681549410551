import type {
  ICountriesAPIResponse,
  ICountry,
  IStatesAPIResponse,
  OptionType,
} from "../types/types";
import { useContext, useState } from "react";
import useSWR from "swr";
import { Notifications } from "../components/Notifications/NotificationsContext";
import { useStoreState } from "./util";

export const useCountriesList = (): OptionType<string>[] => {
  const [countries, setCountries] = useState<OptionType<string>[]>([]);
  const { notifyError } = useContext(Notifications);
  const { storefront_id } = useStoreState();
  useSWR<ICountriesAPIResponse>(
    `/v1/storefronts/${storefront_id}/location/countries`,
    {
      onError: (error) => {
        notifyError("There was an error fetching the countries list.", {
          error,
        });
      },
      refreshInterval: (() => {
        if (!countries.length) return 1000;
        return 0;
      })(),
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      shouldRetryOnError: true,
      onSuccess: (data) => {
        setCountries(
          data.map((country: ICountry) => {
            return { value: country.alpha_2, label: country.name };
          })
        );
      },
    }
  );
  return countries;
};

export const useStatesList = (
  selectedCountry: string | undefined | null
): OptionType[] => {
  const [states, setStates] = useState<OptionType[]>([]);
  const { notifyError } = useContext(Notifications);

  useSWR<IStatesAPIResponse>(
    selectedCountry ? `/v1/location/countries/${selectedCountry}/states` : null,
    {
      onError: (error) => {
        notifyError("There was an error fetching the states list.", { error });
      },
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      shouldRetryOnError: true,
      onSuccess: (data) => {
        setStates(
          data.map((state) => {
            return { value: state.code.split("-").pop(), label: state.name };
          })
        );
      },
    }
  );
  return states;
};
