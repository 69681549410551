import type { AxiosError } from "axios";
import Axios from "axios";
import {
  DeleteIcon,
  EditIcon,
  LockIcon,
  NonVisibleIcon,
  UnLockIcon,
  VisibleIcon,
} from "../../../../../components/Icons/Icons";
import { useTranslation } from "react-i18next";
import { AddAssetsForm } from "./AddAssetsForm";
import { EditAssetForm } from "../EditAssetForm";
import {
  formatDate,
  useStoreState,
  isAxiosError,
  TablePlaceholder,
  useSupportedLanguages,
} from "../../../../../util/util";
import type { DataMutate } from "../../../../../types/types";
import styled, { ThemeContext } from "styled-components";
import { Table } from "../../../../../components/Table/Table";
import React, { useContext, useRef, useState, useEffect, useMemo } from "react";
import { RemoveAssetFromProduct } from "./RemoveAssetFromProduct";
import type {
  Assets,
  GridItemProps,
  PIMProduct,
} from "../../../../../types/types.PIM";
import { SlideOut } from "../../../../../components/SlideOut/SlideOut";
import { H3, SmallText } from "../../../../../components/Typography/Typography";
import {
  ListGridToggleButton,
  PrimaryButtonWithPlusIcon,
} from "../../../../../components/Buttons/Buttons";
import { endpoints } from "../../../../../endpoints";
import { Notifications } from "../../../../../components/Notifications/NotificationsContext";
import { EditDeleteModal } from "../../../../../components/EditDeleteModal/EditDeleteModal";
import { useAuthContext } from "../../../../../components/Auth";
import ReactTooltip from "react-tooltip";
import { AssetNameIcon, DocPortfolioName } from "../util";
import { WithPermission } from "../../../../../components/WithPermission/WithPermission";
import type { ChipType } from "../../../../../components/Chips/Chips";
import { EditOrGenerateProductDocument } from "../EditOrGenerateProductDocuments/EditOrGenerateProductDocument";
import { getLanguageOptionFromSupportedLanguage } from "../../../../admin/SellerAdmin/PIM/SellarAdminPIMAssets/util/AssetsUtil";
import { Row } from "../../../../../components/Layout/Layout";
import { GridAssetView } from "../../../../admin/SellerAdmin/PIM/SellarAdminPIMAssets/GridAssetView/GridAssetView";

const TableContainer = styled.div`
  margin-bottom: 24px;
  & div[class*="TableWrapper"] {
    overflow: visible;
    td {
      overflow: visible;
    }
  }
`;

type TableProduct = {
  id: string | boolean;
  name: string;
};

const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  column-gap: 8px;
`;

const BtnWrapper = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  :disabled {
    cursor: not-allowed;
  }
`;

export const DocumentsOrDigitalMediaTable = ({
  product,
  title,
  assets,
  fetchProductData,
  replaceProductId,
  isOutsideLogin = false,
}: {
  title: "Documents" | "Digital Media";
  product: PIMProduct;
  assets: Assets[];
  fetchProductData: DataMutate<PIMProduct>;
  replaceProductId: (id: string) => void;
  isOutsideLogin?: boolean;
}) => {
  const { t } = useTranslation();
  const theme = useContext(ThemeContext);
  const { tenant_id, is_publishing_product } = useStoreState();
  const { hasPermission, roleIsGuest } = useAuthContext();

  const { notifySuccess, notifyError } = useContext(Notifications);

  const [showSlideOut, setSlideOut] =
    useState<"add" | "edit" | "delete" | "generate" | false>(false);

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [editRowData, setEditRowData] = useState<Assets | null>(null);
  const [deleteRowData, setDeleteRowData] = useState<Assets | null>(null);
  const [list_grid_toggle, set_list_grid_toggle] =
    useState<"list" | "grid">("list");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { supported_languages } = useSupportedLanguages();

  const product_document_ref = useRef<{ clear_poll_timeout: () => void }>(null);

  const closeSlideOut = () => setSlideOut(false);
  const closeDeleteModal = () => setShowDeleteModal(false);

  const [showEditPopup, setShowEditPopup] = useState<boolean>(false);

  const [tableData, setTableData] = useState<TableProduct[]>([]);

  const productStatus = useMemo(() => product.status, [product.status]);

  const tableColumns = React.useMemo(
    () => [
      {
        Header: t("Name"),
        accessor: "name",
        Cell: ({
          value,
          row: {
            original: {
              asset_type,
              signed_url,
              is_downloadable,
              is_generated,
              content_type,
            },
          },
        }: {
          value: string;
          row: {
            original: Assets;
          };
        }) => (
          <AssetNameIcon
            asset_type={asset_type}
            signed_url={signed_url}
            name={value}
            is_downloadable={is_downloadable}
            is_generated={is_generated}
            content_type={content_type}
          />
        ),
      },
      {
        Header: t("Category"),
        accessor: "asset_category",
        Cell: ({
          value,
          row: {
            original: { is_downloadable },
          },
        }: {
          value: string;
          row: { original: Assets };
        }) => (
          <DocPortfolioName disabled={isOutsideLogin && !is_downloadable}>
            {value}
          </DocPortfolioName>
        ),
      },
      {
        Header: t("Language"),
        accessor: "language",
        Cell: ({
          value,
          row: {
            original: { language },
          },
        }: {
          value: string;
          row: { original: Assets };
        }) => (
          <DocPortfolioName disabled={isOutsideLogin && !language}>
            {language?.toLocaleUpperCase()}
          </DocPortfolioName>
        ),
      },
      ...(!isOutsideLogin
        ? [
            {
              Header: t("Last Modified"),
              accessor: "modified_at",
              Cell: ({
                value,
                row: {
                  original: { is_downloadable },
                },
              }: {
                value: string;
                row: { original: Assets };
              }) => (
                <DocPortfolioName disabled={isOutsideLogin && !is_downloadable}>
                  {value}
                </DocPortfolioName>
              ),
            },
          ]
        : []),
      ...(isOutsideLogin
        ? []
        : [
            {
              Header: t("Last Modified By"),
              accessor: "modified_by",
            },
          ]),
      ...(isOutsideLogin
        ? []
        : [
            {
              Header: productStatus !== "archived" ? t("Actions") : "",
              accessor: "is_downloadable",
              Cell: ({
                value,
                row: { original },
              }: {
                value: boolean;
                row: { original: Assets };
              }) =>
                productStatus !== "archived" && (
                  <IconWrapper>
                    {product.is_editable && (
                      <WithPermission permission="modify_assets">
                        <BtnWrapper
                          onClick={() => handleEdit(original)}
                          disabled={is_publishing_product}
                        >
                          <EditIcon
                            fill={
                              is_publishing_product
                                ? theme.disabledButtonBG
                                : theme.primaryIconColor
                            }
                            width={20}
                            height={20}
                          />
                        </BtnWrapper>
                      </WithPermission>
                    )}
                    {title === "Documents" &&
                      (() => {
                        if (
                          hasPermission("modify_assets") &&
                          product.is_editable
                        ) {
                          if (value) {
                            return (
                              <BtnWrapper
                                disabled={is_publishing_product}
                                onClick={() => handleChangeVisibility(original)}
                              >
                                <UnLockIcon
                                  fill={
                                    is_publishing_product
                                      ? theme.disabledButtonBG
                                      : undefined
                                  }
                                  width={20}
                                  height={20}
                                />
                              </BtnWrapper>
                            );
                          } else {
                            return (
                              <BtnWrapper
                                disabled={is_publishing_product}
                                onClick={() => handleChangeVisibility(original)}
                              >
                                <LockIcon
                                  fill={
                                    is_publishing_product
                                      ? theme.disabledButtonBG
                                      : undefined
                                  }
                                  width={20}
                                  height={20}
                                />
                              </BtnWrapper>
                            );
                          }
                        } else {
                          return null;
                        }
                      })()}
                    {title === "Digital Media" &&
                      (() => {
                        if (
                          hasPermission("modify_assets") &&
                          product.is_editable
                        ) {
                          if (value) {
                            return (
                              <div
                                data-tip={
                                  original.is_cover_image
                                    ? t(
                                        "You can't hide the product cover image"
                                      )
                                    : null
                                }
                                data-for={`disabled-cover-image`}
                              >
                                <BtnWrapper
                                  disabled={original.is_cover_image}
                                  onClick={() =>
                                    handleChangeVisibility(original)
                                  }
                                >
                                  <VisibleIcon
                                    width={20}
                                    height={20}
                                    fill={
                                      original.is_cover_image
                                        ? theme.disabledLinkColor
                                        : theme.successIconColor
                                    }
                                  />
                                </BtnWrapper>
                                <ReactTooltip id={`disabled-cover-image`} />
                              </div>
                            );
                          } else {
                            return (
                              <BtnWrapper
                                onClick={() => handleChangeVisibility(original)}
                              >
                                <NonVisibleIcon
                                  width={20}
                                  height={20}
                                  fill={theme.errorColor}
                                />
                              </BtnWrapper>
                            );
                          }
                        } else return null;
                      })()}
                    {product.is_editable && (
                      <WithPermission permission="delete_assets">
                        <BtnWrapper
                          disabled={is_publishing_product}
                          onClick={() => handleDelete(original)}
                        >
                          <DeleteIcon
                            fill={
                              is_publishing_product
                                ? theme.disabledButtonBG
                                : theme.errorColor
                            }
                          />
                        </BtnWrapper>
                      </WithPermission>
                    )}
                  </IconWrapper>
                ),
            },
          ]),
    ],
    [
      t,
      isOutsideLogin,
      productStatus,
      title,
      theme.errorColor,
      theme.disabledLinkColor,
      theme.successIconColor,
      hasPermission,
      product,
      is_publishing_product,
      theme.disabledButtonBG,
      theme.primaryIconColor,
    ]
  );

  const handleChangeVisibility = (original: Assets) => {
    setShowEditPopup(true);
    setEditRowData(original);
  };

  const onComplete = async (productId?: string, shouldFetchData = false) => {
    if (productId) {
      replaceProductId(productId);
    } else {
      if (shouldFetchData) {
        await fetchProductData();
      }
    }
    closeSlideOut();
  };

  const handleDelete = (original: Assets) => {
    setShowDeleteModal(true);
    setDeleteRowData(original);
  };

  const handleEdit = (original: Assets) => {
    setSlideOut("edit");
    setEditRowData(original);
  };

  const handleEditConfirm = async (): Promise<void> => {
    setIsSubmitting(true);
    try {
      const assetId = editRowData ? editRowData.id : "";
      const is_downloadable = editRowData?.is_downloadable;
      const baseURL = endpoints.v2_storefronts_id_pim_assets_id(
        tenant_id,
        assetId
      );

      await Axios.patch(baseURL, { is_downloadable: !is_downloadable });
      await fetchProductData();
      notifySuccess(t("Asset successfully Edited"));
      setShowEditPopup(false);
    } catch (error) {
      if (
        isAxiosError(error) &&
        (error as AxiosError)?.response?.data?.message
      ) {
        notifyError(error?.response?.data?.message);
      } else {
        notifyError(t("could not edit asset, Something went wrong."));
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    const handleProductsData = (assets: Assets[]) => {
      setTableData(
        assets.reduce<
          (TableProduct &
            {
              [Prop in keyof Assets]+?:
                | string
                | number
                | boolean
                | ChipType
                | object;
            })[]
        >(
          (
            acc,
            {
              id,
              name,
              asset_category,
              category,
              modified_at,
              modified_by,
              is_downloadable,
              is_generated,
              asset_type,
              signed_url,
              language,
              number_of_products,
              is_cover_image,
              content_type,
              ...rest
            }
          ) => {
            acc.push({
              id,
              asset_category,
              category,
              name: name ?? "--",
              modified_at: formatDate(modified_at),
              modified_by: modified_by ?? "--",
              is_downloadable,
              is_generated,
              asset_type,
              signed_url,
              language: language?.toLocaleUpperCase(),
              number_of_products,
              is_cover_image,
              content_type: content_type ?? "application/pdf",
              ...rest,
            });
            return acc;
          },
          []
        )
      );
    };
    handleProductsData(assets);
  }, [assets]);

  // Only tds document generation is supported for now
  const hasActiveGeneratedAssetConfig =
    // despite being called `generated_assets`, this is actually a config object.
    product.product_schema.generated_assets.some(
      (genAsset) => genAsset.is_active
    );

  const canGenerateNewTDS = product.assets.every(
    (asset) => asset.is_generated === false
  );

  return (
    <>
      <Row>
        <div>
          <H3 style={{ marginBottom: "4px" }}>
            {title === "Documents" ? t("Documents") : t("Digital Media")}
          </H3>
          <SmallText style={{ marginBottom: "16px" }}>
            {t(`Manage {{title}} and control their accessibility`, { title })}
          </SmallText>
        </div>
        <div style={{ alignSelf: "flex-start" }}>
          <ListGridToggleButton
            set_toggle_state={set_list_grid_toggle}
            name={`${title}${
              isOutsideLogin ? "_portfolio" : ""
            }_list_grid_toggle`}
          />
        </div>
      </Row>
      <TableContainer>
        {list_grid_toggle === "list" ? (
          <Table
            columns={tableColumns}
            data={tableData}
            isLoading={false}
            firstRowWidthPercentage={70}
            // this will never display if the product errors
            error={undefined}
            lastChildleftAlign
            Placeholder={<TablePlaceholder />}
          />
        ) : (
          <GridAssetView
            assets={
              [...tableData].map((asset) => ({
                ...asset,
                can_edit:
                  !isOutsideLogin &&
                  hasPermission("modify_assets") &&
                  productStatus !== "archived" &&
                  product.is_editable,
                can_change_visibility:
                  !isOutsideLogin &&
                  hasPermission("modify_assets") &&
                  productStatus !== "archived" &&
                  product.is_editable,
                can_delete:
                  !isOutsideLogin &&
                  hasPermission("delete_assets") &&
                  productStatus !== "archived" &&
                  product.is_editable,
                can_external_download: roleIsGuest
                  ? (asset as Assets).is_downloadable
                  : true,
                can_view_details: !isOutsideLogin,
              })) as unknown as (Assets & GridItemProps)[]
            }
            handle_asset_visibility_change={handleChangeVisibility}
            on_edit_asset={handleEdit}
            on_remove_asset={handleDelete}
            on_download_asset={(asset) => {
              window.open(asset.signed_url, "_blank");
            }}
          />
        )}
      </TableContainer>

      {showSlideOut === "add" && (
        <SlideOut closeFlyout={closeSlideOut} show={!!showSlideOut}>
          <AddAssetsForm
            title={title}
            onComplete={onComplete}
            product={product}
            fetchProductData={fetchProductData}
          />
        </SlideOut>
      )}

      {showSlideOut === "edit" && (
        <SlideOut
          closeFlyout={async () => {
            closeSlideOut();
            product_document_ref.current?.clear_poll_timeout();
            await fetchProductData();
          }}
          show={!!showSlideOut}
        >
          {editRowData?.is_generated ? (
            <EditOrGenerateProductDocument
              asset={{
                ...editRowData,
                language: getLanguageOptionFromSupportedLanguage(
                  editRowData!.language,
                  supported_languages
                ).value,
              }}
              ref={product_document_ref}
              onSuccess={onComplete}
              product={product}
            />
          ) : (
            <EditAssetForm
              onComplete={onComplete}
              fetchProductData={fetchProductData}
              product={product}
              assetData={editRowData}
              formType={title}
            />
          )}
        </SlideOut>
      )}
      {showSlideOut === "generate" && (
        <SlideOut
          closeFlyout={async () => {
            closeSlideOut();
            product_document_ref.current?.clear_poll_timeout();
            await fetchProductData();
          }}
          show={!!showSlideOut}
        >
          <EditOrGenerateProductDocument
            product={product}
            ref={product_document_ref}
            replaceProductId={replaceProductId}
            onSuccess={onComplete}
          />
        </SlideOut>
      )}
      {showEditPopup && (
        <EditDeleteModal
          title={t("Edit Asset")}
          show={showEditPopup}
          onClose={() => setShowEditPopup(false)}
          onConfirm={handleEditConfirm}
          isLoading={isSubmitting}
          confirmBtnLabel={t("Confirm")}
          hideIcon={true}
          body={t(
            " This change will affect {{number_of_products}} other products. Would you like to continue?",
            { number_of_products: editRowData?.number_of_products }
          )}
        />
      )}

      {showDeleteModal && (
        <RemoveAssetFromProduct
          onComplete={(id) => {
            onComplete(id);
            closeDeleteModal();
          }}
          product={product}
          show={showDeleteModal}
          onClose={closeDeleteModal}
          refreshAssetsList={fetchProductData}
          assetData={deleteRowData}
        />
      )}

      {hasPermission("modify_assets") &&
        productStatus !== "archived" &&
        product.is_editable && (
          <div style={{ display: "flex", gap: "16px" }}>
            <PrimaryButtonWithPlusIcon
              style={{ width: "fit-content" }}
              onClick={() => setSlideOut("add")}
            >
              {t("Add")}
            </PrimaryButtonWithPlusIcon>
            {hasActiveGeneratedAssetConfig &&
              title === "Documents" &&
              canGenerateNewTDS && (
                <PrimaryButtonWithPlusIcon
                  style={{ width: "fit-content" }}
                  onClick={() => setSlideOut("generate")}
                >
                  {t("Generate Document")}
                </PrimaryButtonWithPlusIcon>
              )}
          </div>
        )}
    </>
  );
};
