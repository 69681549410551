import { Auth } from "../../../components/Auth";
import { PrimaryButtonLarge } from "../../../components/Buttons/Buttons";
import { Notifications } from "../../../components/Notifications/NotificationsContext";
import { SelectBoxV2 } from "../../../components/SelectBoxV2/SelectBoxV2";
import { TextField } from "../../../components/TextFields/TextFields";
import { Title } from "../../../components/Typography/Typography";
import type {
  IRegistrationRequest,
  IRegistrationRequestWithAddress,
  LeadConfiguration,
  OptionType,
  SEODetail,
  StorefrontCustomizableFormSchema,
  StorefrontFormData,
  SupportedLanguage,
} from "../../../types/types";
import {
  getStatesInputLabel,
  getZipCodeInputLabel,
} from "../../../util/location";
import { useCountriesList, useStatesList } from "../../../util/Locations";
import {
  getPhoneCodesOptions,
  getPhoneOptionByAlpha2,
} from "../../../util/phone";
import { useRoutePath } from "../../../util/Routing";
import { strings } from "../../../util/strings";
import {
  isAxiosError,
  isValidPhoneNumber,
  useFormWrapper,
  usePolicyDocuments,
  useStoreState,
} from "../../../util/util";
import {
  ColoredTextOnError,
  getBrowserLanguage,
} from "../../../util/util-components";
import {
  Flex,
  Flex1,
  Flex2,
  Form,
  FormGrid2x2,
} from "../../../layout/FormLayout";
import type { AxiosError } from "axios";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Controller, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import useSWR from "swr";
import "yup-phone";
import * as zod from "zod";
import { SEOHelmet } from "../../../components/SEOHelmet/SEOHelmet";
import { endpoints } from "../../../endpoints";
import { zodAddressDefault } from "../../../util/zod.util";
import { DelayedSpinner } from "../../../components/DelayedSpinner/DelayedSpinner";
import { OutsideLoginCustomFields } from "../../admin/SellerAdmin/SellerAdminSettings/ThemeAndContentTab/OutsideLoginCustomFields/OutsideLoginCustomFields";
import {
  CheckBoxContainer,
  CheckBoxFinePrintLabel,
  WarningMessageBox,
} from "../../../components/Form/Form";
import { WarningIcon } from "../../../components/Icons/Icons";
import { ConfirmationSpacer } from "../SampleRequestCart/SampleRequestCart";
import { CheckBoxNoLabel } from "../../../components/CheckBoxes/CheckBoxes";
import { PrivacyPolicyLink } from "../../../components/PrivacyPolicyLink/PrivacyPolicyLink";
import { create_configured_checkboxes_fields } from "../../admin/SellerAdmin/SellerAdminSettings/TermsTab/ConfigureCheckboxsCard/utils";

interface RowProps {
  flex?: number | undefined;
}

const Container = styled.div`
  max-width: 780px;
  margin: 0 auto;
`;

const Row = styled.div<RowProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  & > div {
    flex: ${({ flex }) => flex || 1};
    margin-right: 9px;
  }
  & > div:nth-last-child(1) {
    margin-right: 0;
  }
`;

const RegisterSubtitle = styled.div`
  margin-bottom: 2em;
  line-height: 1.75rem;
`;

export const defaultRegistrationDescription = (t: (s: string) => string) =>
  t(
    "Register to access technical product documentation, such as TDS and SDS, or to request samples and quotes. Please provide your professional details, including the company name and address, in the form below. A member of our team will reach out to you shortly to confirm your registration."
  );

export function Registration() {
  const {
    storefront_id,
    slug,
    storefront_metadata: {
      supported_languages,
      show_privacy_policy_on_contact_forms,
      configured_checkboxes,
    },
  } = useStoreState();
  const { t } = useTranslation();
  const { privacyPolicy } = usePolicyDocuments();
  const registerationCheckboxes = configured_checkboxes.filter(
    (item) => item.registration
  );

  const { data: leadConfiguration, error: leadConfigurationError } =
    useSWR<LeadConfiguration>(
      `/v1/storefronts/${storefront_id}/lead-configuration`
    );

  const { data: leadCustomForms, error: leadCustomFormsError } =
    useSWR<StorefrontCustomizableFormSchema>(
      `/v2/storefronts/${storefront_id}/custom-forms/registration`
    );

  const isLoadingLeadConfiguration =
    !leadConfiguration && !leadConfigurationError;

  const { data: registerPageSEO } = useSWR<SEODetail, AxiosError<SEODetail>>(
    endpoints.v1_storefronts_id_or_slug_seo_page(storefront_id, "register_page")
  );

  // This will default to false for all storefronts except brenntag at time of
  // writing, if the endpoint errors out still show the form without the address portion
  const showAddressOnForm =
    !leadConfigurationError && !!leadConfiguration?.show_location_form;

  const showPrivacyPolicy =
    Boolean(privacyPolicy) && show_privacy_policy_on_contact_forms;

  const RegistrationSchema = zod.object({
    first_name: zod.string().min(1, strings(t).thisIsARequiredField),
    last_name: zod.string().min(1, strings(t).thisIsARequiredField),
    company: zod.string().min(1, strings(t).thisIsARequiredField),
    email: zod.string().email(),
    country_code: zod.object({
      value: zod.string().min(1, strings(t).thisIsARequiredField),
      label: zod.string().min(1, strings(t).thisIsARequiredField),
    }),
    phone_number: zod.string().min(1, strings(t).phoneNumberMustBeValid),
    message: zod.string().optional(),
    arbitrary_fields: zod
      .record(zod.object({ value: zod.string(), label: zod.string() }))
      .optional(),
    confirmation_pv: zod.boolean(),
    configured_checkboxes:
      registerationCheckboxes.length > 0
        ? zod.array(zod.object({ value: zod.boolean() }))
        : zod.undefined(),
  });

  const RegistrationSchemaWithFullAddress = RegistrationSchema.extend(
    zodAddressDefault(t)
  );

  const RegistrationSchemaUnion = zod.union([
    RegistrationSchema,
    RegistrationSchemaWithFullAddress,
  ]);

  type FormInputs = zod.infer<typeof RegistrationSchemaUnion>;

  const methodsOfUseForm = useFormWrapper<FormInputs>();

  const {
    handleSubmit,
    register,
    formState,
    errors,
    control,
    setValue,
    watch,
    setError,
  } = methodsOfUseForm;

  const { user } = useContext(Auth);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { notifyError } = useContext(Notifications);
  const DEFAULT_SUBTITLE = defaultRegistrationDescription(t);
  const [subtitle] = useState<string>(DEFAULT_SUBTITLE);
  const { storePath } = useRoutePath();
  const [cookies] = useCookies([`preferred-language-${slug}`]);
  const preferredLanguage: SupportedLanguage | undefined = cookies[
    `preferred-language-${slug}`
  ] as SupportedLanguage;
  const browserLanguage: SupportedLanguage | undefined =
    getBrowserLanguage() as SupportedLanguage;

  const langs = Object.keys(supported_languages);

  const [phoneNumberWarning, setPhoneNumberWarning] =
    useState<{ message: string } | null>(null);

  const countries = useCountriesList();
  const maybeCountry: OptionType<string> | null = watch("country");
  const selectedCountry = maybeCountry ? maybeCountry.value : "";
  const states = useStatesList(selectedCountry);

  const onSubmit = async (inputs: FormInputs) => {
    // Validate email via sendgrid
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      const response = await axios.post(`/v1/emails/validate`, {
        email: inputs.email,
        source: "register form",
      });

      if (response.data.valid) {
        postFormData(inputs);
      }
    } catch (error) {
      setError("email", { message: strings(t).emailAddressMustBeValid });
      notifyError(t("Email validation error, please try again"), { error });
      setLoading(false);
    }
  };

  const { data: storefrontFormData, error: formDataError } = useSWR<
    StorefrontFormData,
    AxiosError<StorefrontFormData>
  >(endpoints.v1_storefronts_id_form_data(storefront_id, "en"));

  const defaultRegisterDescription = subtitle;
  const registrationDescription =
    storefrontFormData?.data?.Register?.Description;
  const postFormData = async (inputs: FormInputs) => {
    // Submit form to Agilis API
    try {
      const {
        first_name,
        last_name,
        company,
        email,
        phone_number,
        country_code,
      } = inputs;

      if (
        !isValidPhoneNumber({ phone_number, country_code }) &&
        !phoneNumberWarning
      ) {
        // only display this once, if the user clicks submit a second time let
        // them go
        setPhoneNumberWarning({
          message: t(
            "phone number might be invalid, press submit again to continue"
          ),
        });

        return;
      }

      const maybeValuesWithFullAddress = showPrivacyPolicy
        ? RegistrationSchemaWithFullAddress.safeParse(inputs)
        : RegistrationSchemaWithFullAddress.omit({
            confirmation_pv: true,
          }).safeParse(inputs);

      const customChoices: Record<string, string> = {};

      for (const fieldName in inputs.arbitrary_fields) {
        customChoices[fieldName] = inputs?.arbitrary_fields?.[fieldName]?.label;
      }

      let dataToPost: IRegistrationRequest | IRegistrationRequestWithAddress = {
        seller_id_or_slug: storefront_id,
        buyer_first_name: first_name,
        buyer_last_name: last_name,
        buyer_email: email,
        buyer_phone_number: `${country_code.value}${phone_number}`,
        buyer_company_name: company,
        custom_choices: customChoices,
        language:
          preferredLanguage ??
          user?.preferred_language ??
          (() => {
            const supportedLanguage = langs.find(
              (lang) => lang === browserLanguage
            );
            if (supportedLanguage) {
              return supportedLanguage;
            } else {
              return null;
            }
          })() ??
          "en",
      };

      if (maybeValuesWithFullAddress.success) {
        const {
          country,
          city,
          state,
          county,
          postal_code,
          address1,
          address2,
        } = maybeValuesWithFullAddress.data;
        dataToPost = {
          ...dataToPost,
          address: {
            country: country.value,
            state: state.value,
            city,
            postal_code,
            address1,
            address2: address2 ?? null,
            county: county ?? "",
          },
        };
      }

      const response = await axios.post(
        `/v1/storefronts/${storefront_id}/leads/register`,
        dataToPost
      );

      if (response.status === 208) {
        notifyError(
          t(
            "You are already registered on the storefront. Please log in to raise the request"
          )
        );
        history.push(`${storePath}/login`);
      }

      if (response.status === 201) {
        setLoading(false);
        history.push(`${storePath}/register/thank-you`);
      }
    } catch (error) {
      if (isAxiosError(error) && error?.response?.status === 409) {
        notifyError(t(`Registration request is already in progress`));
        setLoading(false);
        return;
      }
      notifyError(t("There was an error, please try again"), { error });
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const { fields, append } = useFieldArray({
    control,
    name: "configured_checkboxes",
  });

  useEffect(() => {
    if (registerationCheckboxes.length > 0 && fields?.length < 1) {
      registerationCheckboxes.forEach((element) => {
        append({ value: false, label: element.name, id: element.id });
      });
    }
  }, [registerationCheckboxes, append, fields]);

  useEffect(() => {
    if (showAddressOnForm && countries.length > 0 && leadConfiguration) {
      setValue(
        "country",
        countries.find(
          (country) => country.value === leadConfiguration.default_country
        )
      );
    }
  }, [showAddressOnForm, countries, leadConfiguration, setValue]);

  useEffect(() => {
    if (selectedCountry) {
      setValue("city", undefined);
      setValue("state", null);
      setValue("phone_number", undefined);
      setValue("country_code", getPhoneOptionByAlpha2(selectedCountry));
    }
  }, [selectedCountry, setValue]);

  if (isLoadingLeadConfiguration) {
    return <DelayedSpinner />;
  }

  return (
    <Container>
      {/* Handle setting the SEO information every time it changes */}
      {registerPageSEO && <SEOHelmet seo={registerPageSEO} />}
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Title>{t("Register")}</Title>
        <RegisterSubtitle>
          {registrationDescription && !formDataError && (
            <div
              dangerouslySetInnerHTML={{
                __html: `<div class="ql-editor">${registrationDescription}</div>`,
              }}
            ></div>
          )}
          {!registrationDescription &&
            !storefrontFormData?.data?.Register &&
            !formDataError &&
            storefrontFormData &&
            defaultRegisterDescription}
          {formDataError && defaultRegisterDescription}
        </RegisterSubtitle>
        {phoneNumberWarning && (
          <WarningMessageBox>
            <div style={{ marginRight: "4px" }}>
              <WarningIcon width={18} />
            </div>
            {t(
              `The phone number provided cannot be validated, if you're confident it is correct please press "submit" again to continue`
            )}
          </WarningMessageBox>
        )}
        <Row>
          <TextField
            name="first_name"
            label={t("First Name")}
            theref={register({
              required: true,
            })}
            formState={formState}
            errors={errors}
            type="text"
          />
          <TextField
            name="last_name"
            label={t("Last Name")}
            theref={register({
              required: true,
            })}
            formState={formState}
            errors={errors}
            type="text"
          />
        </Row>
        <Row>
          <TextField
            name="company"
            label={t("Company")}
            autoComplete="organization"
            theref={register({
              required: true,
            })}
            formState={formState}
            errors={errors}
            type="text"
          />
          <TextField
            name="email"
            label={t("Business Email Address")}
            theref={register({
              required: true,
            })}
            formState={formState}
            errors={errors}
            type="email"
          />
        </Row>
        {showAddressOnForm && countries.length > 0 && leadConfiguration && (
          <FormGrid2x2>
            <Controller
              as={SelectBoxV2}
              control={control}
              name="country"
              autoComplete={"country-name"}
              placeholder={t("Country")}
              // isSearchBar
              options={countries}
              rules={{
                required: true,
              }}
              errors={errors}
              formState={formState}
            />
            <Controller
              as={SelectBoxV2}
              control={control}
              name="state"
              autoComplete="address-level1"
              placeholder={getStatesInputLabel(selectedCountry, t)}
              options={states}
              // isSearchBar
              rules={{
                required: true,
              }}
              errors={errors}
              formState={formState}
            />
          </FormGrid2x2>
        )}
        {showAddressOnForm && (
          <Row flex={0.5}>
            <TextField
              name="address1"
              autoComplete="address-line-1"
              label={t("Address Line 1")}
              theref={register({
                required: true,
              })}
              formState={formState}
              errors={errors}
              type="text"
            />
            <TextField
              name="address2"
              autoComplete="address-line-2"
              label={t("Address Line 2")}
              theref={register({
                required: false,
              })}
              formState={formState}
              errors={errors}
              type="text"
            />
          </Row>
        )}
        {showAddressOnForm && (
          <Row flex={0.5}>
            <TextField
              name="city"
              label={t("City")}
              theref={register({
                required: true,
              })}
              formState={formState}
              errors={errors}
              type="text"
            />
            <TextField
              name="postal_code"
              autoComplete="postal-code"
              label={getZipCodeInputLabel(selectedCountry, t)}
              theref={register({
                required: true,
              })}
              formState={formState}
              errors={errors}
              type="text"
            />
          </Row>
        )}
        {leadConfiguration && (
          <>
            <Row>
              <Flex>
                <Flex1>
                  <Controller
                    as={SelectBoxV2}
                    control={control}
                    name="country_code"
                    autoComplete="countryCode"
                    placeholder={t("Country Code")}
                    id="countryCodeSelectBox"
                    options={getPhoneCodesOptions()}
                    defaultValue={getPhoneOptionByAlpha2(
                      leadConfiguration.default_country_code
                    )}
                    rules={{
                      required: true,
                    }}
                    errors={errors}
                    formState={formState}
                  />
                </Flex1>
                <Flex2 style={{ marginRight: 0, marginLeft: "14px" }}>
                  <TextField
                    name="phone_number"
                    label={t("Phone Number")}
                    theref={register({
                      required: true,
                    })}
                    formState={formState}
                    errors={errors}
                    warningText={phoneNumberWarning}
                    type="tel"
                  />
                </Flex2>
              </Flex>
            </Row>
          </>
        )}
        {leadCustomForms &&
          !leadCustomFormsError &&
          leadCustomForms.is_active && (
            <OutsideLoginCustomFields
              fields={leadCustomForms}
              methodsOfUseForm={methodsOfUseForm}
            />
          )}
        {showPrivacyPolicy && (
          <ConfirmationSpacer>
            <CheckBoxContainer>
              <div style={{ width: "22px", marginRight: "15px" }}>
                <CheckBoxNoLabel
                  name="confirmation_pv"
                  id="confirmation_pv"
                  ref={register({
                    validate: (val: boolean) =>
                      val || strings(t).thisIsARequiredField,
                  })}
                  error={!!errors.confirmation_pv?.message}
                />
              </div>
              <CheckBoxFinePrintLabel htmlFor="confirmation_pv">
                <ColoredTextOnError isError={!!errors.confirmation_pv?.message}>
                  <>
                    <span>{t("I agree to the ")}</span>
                    <PrivacyPolicyLink customPolicy />
                    <span>.</span>
                  </>
                </ColoredTextOnError>
              </CheckBoxFinePrintLabel>
            </CheckBoxContainer>
          </ConfirmationSpacer>
        )}
        {fields.length > 0 && (
          <>
            {create_configured_checkboxes_fields({
              fields,
              methodsOfUseForm,
              t,
            })}
          </>
        )}
        <PrimaryButtonLarge
          style={{ minWidth: "242px", marginTop: "15px" }}
          loading={loading}
        >
          {t("Submit")}
        </PrimaryButtonLarge>
      </Form>
    </Container>
  );
}
