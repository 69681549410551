import { Modal } from "../Modal/Modal";
import { useOnClickOutside } from "../../util/hooks";
import isEqual from "lodash/isEqual";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { TertiaryButtonSmall } from "../Buttons/Buttons";
import type { ChipType } from "../Chips/Chips";
import { PrefilledSelectAllChips } from "../PrefilledSelectAllChips/PrefilledSelectAllChips";
import type { IIsOutOfView } from "../../util/util";
import { isOutOfViewport, useMediaQueries } from "../../util/util";
import { chipsToStringArray } from "../Filters/Filters";
import { StringParam, useQueryParams } from "use-query-params";

const DropDownWrapper = styled.div`
  display: inline-block;
  position: relative;
  margin-right: 10px;
  button {
    cursor: pointer;
  }
`;
interface IDropDownModalWrapperProps {
  isOutOfViewport?: IIsOutOfView;
}
const DropDownModalWrapper = styled.div<IDropDownModalWrapperProps>`
  position: absolute;
  top: 100%;
  left: ${({ isOutOfViewport }) =>
    isOutOfViewport && isOutOfViewport?.right > 480
      ? 0
      : isOutOfViewport &&
        isOutOfViewport?.right < 480 &&
        isOutOfViewport?.left > 480
      ? "auto"
      : `-${(isOutOfViewport && isOutOfViewport?.left - 25) || 0}px`};

  right: ${({ isOutOfViewport }) =>
    isOutOfViewport && isOutOfViewport?.right > 480
      ? "auto"
      : isOutOfViewport &&
        isOutOfViewport?.right < 480 &&
        isOutOfViewport?.left > 480
      ? 0
      : "auto"};
  width: 500px;
  max-width: 500px;
  background: ${({ theme }) => theme.primaryBG};
  padding: 20px;
  box-shadow: 0 4px 8px 0 ${({ theme }) => theme.shadowColor};
  border-radius: 2px;
  z-index: 900 !important;
`;

interface IFilterBy {
  setter: ({ values, filter }: { values: ChipType[]; filter: string }) => void;
  options: ChipType[];
  header: string;
  filter: string;
  label: string;
  preselectedFilters: ChipType[];
}

/**
 * Reads in filters from the URL if they exist and applies them to the chips.
 * selectedFilters will update when a user clicks on a chip, but they will only be
 * applied if "apply" or "clear" are clicked. When that array changes the request will run.
 */
export const FilterBy = ({
  setter,
  options,
  header,
  filter,
  label,
  preselectedFilters,
}: IFilterBy) => {
  const [showDropDown, setShowDropDown] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState<ChipType[]>(
    preselectedFilters || []
  );
  const [appliedFilters, setAppliedFilters] = useState<ChipType[]>(
    preselectedFilters || []
  );
  const [showModal, setShowModal] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [query, setQuery] = useQueryParams({
    active_tag_view: StringParam,
  });
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [ButtonViewport, setButtonViewport] = useState<
    IIsOutOfView | undefined
  >(isOutOfViewport(buttonRef));

  const handleFilterClick = (activeChips: ChipType[]) => {
    setSelectedFilters([...activeChips]);
  };

  const { t } = useTranslation();

  const getButtonText = useCallback(() => {
    // If there are filters from the URL set the button text based off them.
    let filtersList = chipsToStringArray(appliedFilters);
    if (filtersList.length === 0) {
      return `${label}`;
    } else if (filtersList.length < 3) {
      return filtersList.join(", ");
    } else {
      return `${filtersList[0]}, ${filtersList[1]} ${t("and")} ${
        filtersList.length - 2
      } ${t("more")}`;
    }
  }, [appliedFilters, label, t]);
  const [buttonText, setButtonText] = useState(getButtonText);

  const handleClickOutside = () => {
    setButtonText(getButtonText());

    if (!isEqual(appliedFilters, selectedFilters)) {
      // When the user clicks outside the modal without applying or clearing
      // and there are changes present
      // reset the selected filters with the applied filters.
      setSelectedFilters(appliedFilters);
      setButtonText(getButtonText());
    }
    setShowDropDown(false);
  };

  useEffect(() => {
    setButtonText(getButtonText());
  }, [appliedFilters, getButtonText]);

  useOnClickOutside(wrapperRef, () => handleClickOutside());

  const apply = () => {
    setAppliedFilters(selectedFilters);
    setter({ values: selectedFilters, filter: filter });
    setButtonText(getButtonText());
    setShowDropDown(false);
  };

  const clear = useCallback(() => {
    // useCallback is needed here because this a dep of the
    // below useEffect
    setSelectedFilters([]);
    setAppliedFilters([]);
    setQuery({
      [filter]: query.active_tag_view,
    });
    setter({ values: [], filter: filter });
    setShowDropDown(false);
  }, [setter, filter, query.active_tag_view, setQuery]);

  useEffect(() => {
    setSelectedFilters(preselectedFilters);
    setAppliedFilters(preselectedFilters);
  }, [preselectedFilters]);

  React.useEffect(() => {
    const handleResize = () => setButtonViewport(isOutOfViewport(buttonRef));
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const { isMediumScreen } = useMediaQueries();
  if (!isMediumScreen) {
    return (
      <DropDownWrapper>
        <TertiaryButtonSmall
          active={appliedFilters.length > 0 || showDropDown}
          ref={buttonRef}
          onClick={() => {
            setShowDropDown(!showDropDown);
            setButtonViewport(isOutOfViewport(buttonRef));
          }}
        >
          {buttonText}
        </TertiaryButtonSmall>
        {showDropDown && (
          <DropDownModalWrapper
            ref={wrapperRef}
            className="dropdownWrapperDiv"
            isOutOfViewport={ButtonViewport}
          >
            <PrefilledSelectAllChips
              allChips={options}
              handleClick={handleFilterClick}
              header={header}
              withSelectAll={true}
              selectedChips={selectedFilters}
              ToggleLabel={label}
              handleApply={apply}
              handleClear={clear}
              scroll={true}
            />
          </DropDownModalWrapper>
        )}
      </DropDownWrapper>
    );
  } else {
    return (
      <DropDownWrapper>
        <TertiaryButtonSmall
          active={appliedFilters.length > 0}
          onClick={() => {
            setShowModal(true);
          }}
        >
          {buttonText}
        </TertiaryButtonSmall>
        <Modal show={showModal} closeModal={() => setShowModal(false)}>
          <PrefilledSelectAllChips
            allChips={options}
            handleClick={handleFilterClick}
            header={header}
            withSelectAll={true}
            selectedChips={selectedFilters}
            ToggleLabel={label}
            handleApply={() => {
              apply();
              setShowModal(false);
            }}
            handleClear={() => {
              clear();
              setShowModal(false);
            }}
          />
        </Modal>
      </DropDownWrapper>
    );
  }
};
