import { zodResolver } from "@hookform/resolvers/zod";
import type { Dispatch, SetStateAction } from "react";
import { useCallback, useEffect } from "react";
import React, { forwardRef, useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import {
  PrimaryButtonFitContainer,
  PrimaryButtonSmall,
  SecondaryButtonFitContainer,
} from "../../../../../components/Buttons/Buttons";
import { DelayedSpinner } from "../../../../../components/DelayedSpinner/DelayedSpinner";
import { ErrorPlaceholder } from "../../../../../components/Error";
import { endpoints } from "../../../../../endpoints";
import type {
  ListPostArgSchema,
  PaginatedListSchema,
  ShortListSchema,
} from "../../../../../types/types.PIM";
import { useFormWrapper, useStoreState } from "../../../../../util/util";
import {
  ListItem,
  ListItemBase,
  ListItemName,
  ListItemWrapper,
  ListSchemaFn,
} from "./ListItem";
import { z } from "zod";
import { Notifications } from "../../../../../components/Notifications/NotificationsContext";
import type { AxiosError, AxiosResponse } from "axios";
import Axios from "axios";
import type {
  DataMutate,
  UUID,
  WithPagination,
} from "../../../../../types/types";
import { CaretRight, PlusIcon } from "../../../../../components/Icons/Icons";
import { useAuthContext } from "../../../../../components/Auth";
import { Pagination } from "../../../../../components/Pagination/Pagination";
import { StringParam, useQueryParams } from "use-query-params";
import { SlideOut } from "../../../../../components/SlideOut/SlideOut";
import { HeaderLeft, PageTitle } from "../../../../../components/Layout/Layout";
import { Flex, Form } from "../../../../../layout/FormLayout";
import { TextField } from "../../../../../components/TextFields/TextFields";
import { ToggleSwitchV2 } from "../../../../../components/ToggleSwitch/ToggleSwitch";

interface IListHomeProps {
  lists?: WithPagination<{ data: ShortListSchema[] }>;
  archivedLists?: WithPagination<{ data: ShortListSchema[] }>;
  listError: any;
  mutateList: DataMutate<PaginatedListSchema>;
  onSelected: (
    list: ShortListSchema | undefined,
    showArchived?: boolean
  ) => void;
  offset: number;
  setOffset: Dispatch<SetStateAction<number>>;
}

const ListHomeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
`;

const ListWrapper = styled.div`
  height: 100%;
  overflow: auto;
  min-height: 250px;
`;

export const ListHeader = styled(ListItemBase)`
  min-height: 58px;
  background-color: ${({ theme }) => theme.colors["gray-3"]};
  cursor: auto;
`;

export const ListFooter = styled(ListItemBase)`
  flex: 1;
  padding: 16px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  cursor: auto;
`;

export const IconContainer = styled.div`
  align-self: flex-end;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  height: 100%;
  align-items: center;
`;

const ListItemNameSchema = z.object({
  name: z.string(),
  icons_enabled: z.boolean(),
});

export type ListItemFormValue = z.infer<typeof ListItemNameSchema>;

export const ARCHIVED_UUID = "archived_name";

export const ListHome = forwardRef<HTMLDivElement, IListHomeProps>(
  (
    {
      lists,
      archivedLists,
      mutateList,
      onSelected,
      listError,
      setOffset,
      offset,
    }: IListHomeProps,
    ref
  ) => {
    const [selectedListID, setSelectedListID] = useState<UUID>();
    const [showAddList, setShowAddList] = useState(false);
    const perPage = 10;
    const [pagination, setPagination] = useState({
      perPage,
      pageCount: 0,
      pageIndex: 0,
    });
    const [query, setQuery] = useQueryParams({
      selectedListID: StringParam,
      selectedArchivedListID: StringParam,
    });
    const { t } = useTranslation();
    const addListRef =
      useRef<{ setEditMode: (editMode: boolean) => void; focus: () => void }>(
        null
      );
    const addListUseForm = useFormWrapper({
      resolver: zodResolver(ListSchemaFn(t)),
    });
    const { tenant_id } = useStoreState();
    const { notifySuccess, notifyError } = useContext(Notifications);
    const { hasPermission } = useAuthContext();

    const handleClick = useCallback(
      (item: ShortListSchema | undefined) => {
        setSelectedListID(item?.id);
        onSelected(item);
        setQuery({ selectedListID: undefined });
      },
      [onSelected, setQuery]
    );

    const handleAddList = () => {
      setShowAddList(true);
      handleClick(undefined);
      setTimeout(() => addListRef.current?.setEditMode(true));
    };

    const handleAddListConfirm = async ({
      name: newName,
      icons_enabled,
    }: ListItemFormValue) => {
      try {
        const { data } = await Axios.post<
          ListPostArgSchema,
          AxiosResponse<ShortListSchema>
        >(endpoints.v2_tenants_id_or_slug_pim_lists(tenant_id), {
          name: newName.trim(),
          icons_enabled: icons_enabled,
        });
        notifySuccess(t("List created successfully"));
        setShowAddList(false);
        await mutateList();
        handleClick(data);
      } catch (error) {
        const errorMessage = (error as AxiosError)?.response?.data?.message;
        notifyError(
          errorMessage
            ? errorMessage
            : t("Could not add list. Something went wrong."),
          {
            error,
          }
        );
      }
    };

    const handleShowArchived = useCallback(() => {
      onSelected(undefined, true);
      setSelectedListID(ARCHIVED_UUID);
    }, [onSelected]);

    const isLoading = !lists && !listError;

    useEffect(() => {
      setPagination({
        perPage,
        pageCount: Math.ceil((lists?.pagination.total ?? 10) / perPage),
        pageIndex: (lists?.pagination.offset ?? 10) / perPage + 1,
      });
    }, [lists?.pagination.offset, lists?.pagination.total]);

    useEffect(() => {
      if (query.selectedArchivedListID) {
        handleShowArchived();
      }
      if (query.selectedListID && lists?.data) {
        const selectedList = lists.data.find(
          (list) => list.id === query.selectedListID
        );
        handleClick(selectedList);
      }
    }, [
      query.selectedListID,
      lists?.data,
      handleClick,
      query.selectedArchivedListID,
      handleShowArchived,
    ]);

    if (isLoading) {
      return <DelayedSpinner />;
    }

    if (listError) {
      return (
        <ErrorPlaceholder
          message={t(
            "There was an error fetching lists. Please try again later."
          )}
        />
      );
    }

    return (
      <ListHomeWrapper ref={ref}>
        <ListHeader>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div>{t("Lists")}</div>
            <div>
              {hasPermission("modify_lists") && (
                <PrimaryButtonSmall
                  onClick={handleAddList}
                  style={{ padding: "8px 15px 8px 10px" }}
                >
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                    }}
                  >
                    <PlusIcon width={18} height={18} />
                    <span>{t("New List")}</span>
                  </span>
                </PrimaryButtonSmall>
              )}
            </div>
          </div>
        </ListHeader>
        <ListWrapper>
          {lists!.data.length > 0 &&
            lists!.data.map((item) => (
              <ListItem
                key={item.id}
                item={item}
                active={selectedListID === item.id}
                selectItem={(reset) =>
                  handleClick(reset === true ? undefined : item)
                }
                mutateList={mutateList}
              />
            ))}
        </ListWrapper>
        {archivedLists && archivedLists.pagination.total > 0 && (
          <ListItemWrapper
            onClick={handleShowArchived}
            active={selectedListID === ARCHIVED_UUID}
          >
            <ListItemName>{`${t("Archived")} (${
              archivedLists.pagination.total
            })`}</ListItemName>
            <IconContainer>
              <CaretRight />
            </IconContainer>
          </ListItemWrapper>
        )}
        {pagination.pageCount > 1 && (
          <ListItemBase style={{ height: "fit-content" }}>
            <Pagination
              pagination={pagination}
              offset={offset}
              handlePageClick={(offset) => setOffset(offset)}
              miniPagination={true}
            />
          </ListItemBase>
        )}

        <SlideOut
          show={!!showAddList}
          closeFlyout={() => setShowAddList(false)}
        >
          <HeaderLeft>
            <PageTitle>{t("Add List")}</PageTitle>
          </HeaderLeft>
          <Form
            noValidate
            onSubmit={addListUseForm.handleSubmit(handleAddListConfirm)}
          >
            <TextField
              name="name"
              label={t("List Name")}
              theref={addListUseForm.register({
                required: true,
              })}
              formState={addListUseForm.formState}
              errors={addListUseForm.errors}
              type="text"
            />
            <div style={{ marginBottom: "25px" }}>
              <ToggleSwitchV2
                label={t("Enable icons for list values")}
                name="icons_enabled"
                ref={addListUseForm.register({ required: false })}
                style={{ marginBottom: "30px" }}
              />
            </div>

            <Flex style={{ marginTop: "30px" }}>
              <SecondaryButtonFitContainer
                onClick={() => setShowAddList(false)}
                style={{ marginRight: "10px" }}
              >
                {t("Cancel")}
              </SecondaryButtonFitContainer>
              <PrimaryButtonFitContainer
                type="submit"
                style={{ marginLeft: "10px" }}
              >
                {t("Save")}
              </PrimaryButtonFitContainer>
            </Flex>
          </Form>
        </SlideOut>
      </ListHomeWrapper>
    );
  }
);
