import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components/macro";
import {
  PageHeader,
  PageTitle,
  PageWrapper,
} from "../../../../../layout/portalPageLayout";
import type {
  ListPatchArgSchema,
  PaginatedListSchema,
  ShortListSchema,
} from "../../../../../types/types.PIM";
import { ListHome } from "./ListHome";
import { ListDetails } from "./ListDetails";
import useSWR from "swr";
import { endpoints } from "../../../../../endpoints";
import { useStoreState } from "../../../../../util/util";
import type { AxiosError } from "axios";
import axios from "axios";
import { useNotifications } from "../../../../../components/Notifications/NotificationsContext";
import { useTranslation } from "react-i18next";
import { ArchivedListDetails } from "./ArchivedListDetails";
import { SearchBar } from "../../../../../components/SearchBar/SearchBar";
import { StringParam, useQueryParams } from "use-query-params";
import { useDebounce } from "../../../../../util/hooks";
import { SecondaryButtonWithImportIcon } from "../../../../../components/Buttons/Buttons";
import { SlideOut } from "../../../../../components/SlideOut/SlideOut";
import { BulkListUploadFromCSV } from "./BulkListUploadFromCSV";
import type { DataMutate, WithPagination } from "../../../../../types/types";
import { WithPermission } from "../../../../../components/WithPermission/WithPermission";

const TopAreaWrapper = styled.div``;

const ListWrapper = styled.div<{ height: string }>`
  display: grid;
  grid-template-columns: 40% 60%;
  border: ${({ theme }) => `1px solid ${theme.secondaryBorder}`};
  width: 100%;
  height: ${({ height }) => height};
`;

export const SellerAdminPIMListHomePage = () => {
  const [selectedList, setSelectedList] = useState<ShortListSchema>();
  const [archivedLists, setArchivedLists] =
    useState<WithPagination<{ data: ShortListSchema[] }>>();
  const [activeLists, setActiveLists] =
    useState<WithPagination<{ data: ShortListSchema[] }>>();
  const [showArchived, setShowArchived] = useState(false);
  const [showListImportForm, setShowListImportForm] = useState(false);
  const [offset, setOffset] = useState(0);
  const [query, setQuery] = useQueryParams({
    q: StringParam,
  });
  const perPage = 50;
  const [searchQuery, setSearchQuery] = useState(query.q || "");
  const [debouncedSearchQuery] = useDebounce(searchQuery, 1000);
  const [pageHeight, setPageHeight] = useState<string>("70vh");
  const { tenant_id } = useStoreState();
  const { notifySuccess, notifyError } = useNotifications();
  const { t } = useTranslation();
  const { error: listError, mutate: mutateLists } = useSWR<PaginatedListSchema>(
    [
      endpoints.v2_tenants_id_or_slug_pim_lists(tenant_id),
      useMemo(
        () => ({
          params: {
            q: debouncedSearchQuery,
            limit: perPage,
            offset,
            order_by: "asc",
            status: "active",
          },
        }),
        [debouncedSearchQuery, offset]
      ),
    ],
    {
      revalidateOnFocus: false,
      onSuccess: ({ data, pagination }) =>
        setActiveLists({
          data: data.filter(({ parent }) => !Boolean(parent)),
          pagination,
        }),
    }
  );

  const { mutate: mutateArchivedLists } = useSWR<PaginatedListSchema>(
    [
      endpoints.v2_tenants_id_or_slug_pim_lists(tenant_id),
      useMemo(
        () => ({
          params: {
            q: debouncedSearchQuery,
            limit: perPage,
            offset,
            order_by: "asc",
            status: "archived",
          },
        }),
        [debouncedSearchQuery, offset]
      ),
    ],
    {
      revalidateOnFocus: false,
      onSuccess: (result) => setArchivedLists(result),
    }
  );

  const mutateAllLists = async () => {
    await mutateLists();
    await mutateArchivedLists();
  };

  const handleImportListClick = (e: React.MouseEvent<HTMLButtonElement>) =>
    setShowListImportForm(true);

  const onSelectList = (
    list: ShortListSchema | undefined,
    showArchived: boolean = false
  ) => {
    if (showArchived) {
      setShowArchived(true);
    } else {
      const foundList = activeLists!.data.find((item) => item.id === list?.id);
      setSelectedList(foundList ?? list);
      setShowArchived(false);
    }
  };

  const onRestore = async ({ id }: ShortListSchema) => {
    try {
      await axios.patch<ListPatchArgSchema, ShortListSchema>(
        endpoints.v2_tenants_id_or_slug_pim_lists_id(tenant_id, id),
        {
          is_deleted: false,
        }
      );
      notifySuccess(t("List restored successfully"));
      if (archivedLists!.pagination.total === 1) {
        setShowArchived(false);
        setSelectedList(undefined);
      }
      await mutateAllLists();
    } catch (error) {
      const errorMessage = (error as AxiosError)?.response?.data?.message;
      notifyError(
        errorMessage
          ? errorMessage
          : t("Could not restore list. Something went wrong."),
        {
          error,
        }
      );
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSearchQuery(e.target.value);

  const handleClearSearch = () => {
    setSearchQuery("");
    setQuery({ q: undefined });
    setSelectedList(undefined);
  };

  useEffect(() => {
    if (activeLists) {
      setSelectedList(
        (prev) => activeLists.data.find((val) => val.id === prev?.id) ?? prev
      );
    }
  }, [activeLists]);

  useEffect(() => {
    if (debouncedSearchQuery === "") {
      setQuery({ q: undefined });
    }
    if (debouncedSearchQuery) {
      setQuery({ q: debouncedSearchQuery });
    }
    setSelectedList(undefined);
  }, [setQuery, debouncedSearchQuery]);

  const topAreaHeightRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setPageHeight(
      topAreaHeightRef.current
        ? `calc(100% - ${topAreaHeightRef.current.clientHeight + 65}px)`
        : "70vh"
    );
  }, [activeLists, archivedLists]);

  return (
    <PageWrapper style={{ height: "100%" }}>
      <TopAreaWrapper ref={topAreaHeightRef}>
        <PageHeader>
          <PageTitle>{t("Lists")}</PageTitle>
        </PageHeader>
        <div
          style={{
            marginBottom: "40px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <SearchBar
            query={searchQuery}
            placeHolder={t("Search by list name or value")}
            handleChange={handleSearch}
            handleClearInput={handleClearSearch}
            maxWidth="450px"
          />
          <WithPermission permission="modify_lists">
            <SecondaryButtonWithImportIcon onClick={handleImportListClick}>
              {t("Import")}
            </SecondaryButtonWithImportIcon>
          </WithPermission>

          <SlideOut
            show={showListImportForm}
            closeFlyout={() => setShowListImportForm(false)}
          >
            <BulkListUploadFromCSV
              onSuccess={() => {
                mutateAllLists();
                setShowListImportForm(false);
              }}
            />
          </SlideOut>
        </div>
      </TopAreaWrapper>
      {(activeLists?.pagination?.total ?? 0) > 0 ||
      (archivedLists?.pagination?.total ?? 0) > 0 ? (
        <ListWrapper height={pageHeight}>
          <ListHome
            lists={activeLists}
            archivedLists={archivedLists}
            mutateList={mutateAllLists as DataMutate<PaginatedListSchema>}
            listError={listError}
            onSelected={onSelectList}
            offset={offset}
            setOffset={setOffset}
          />
          {selectedList && !showArchived && (
            <ListDetails
              mutateList={mutateAllLists as DataMutate<PaginatedListSchema>}
              item={selectedList}
              pageHeight={pageHeight}
            />
          )}
          {showArchived && (
            <ArchivedListDetails
              searchQuery={debouncedSearchQuery}
              onRestore={onRestore}
              pageHeight={pageHeight}
            />
          )}
        </ListWrapper>
      ) : (
        <span>{t("No list name or value matches your search query")}</span>
      )}
    </PageWrapper>
  );
};
