import * as z from "zod";
import {
  useFormWrapper,
  useStoreState,
  isAxiosError,
  toTitleCase,
} from "../../../../../../util/util";
import styled from "styled-components";
import { stringIsNotOnlyWhiteSpaceRegex } from "../../../../../../util/regexes";
import type { AxiosError } from "axios";
import Axios from "axios";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { PrimaryButtonFitContainer } from "../../../../../../components/Buttons/Buttons";
import { endpoints } from "../../../../../../endpoints";
import { Form } from "../../../../../../layout/FormLayout";
import type {
  Assets,
  SupportedAssetCategoryType,
  SupportedAssetType,
} from "../../../../../../types/types.PIM";
import React, { useState, useContext } from "react";
import {
  zodSelectBoxDefault,
  zodSelectBoxType,
} from "../../../../../../util/zod.util";
import { SectionTitle } from "../../../../../../components/Form/Form";
import { HeaderLeft } from "../../../../../../components/Layout/Layout";
import { TextField } from "../../../../../../components/TextFields/TextFields";
import { SelectBoxV2 } from "../../../../../../components/SelectBoxV2/SelectBoxV2";
import { Notifications } from "../../../../../../components/Notifications/NotificationsContext";
import { getNameAndExtension } from "../../../../../SharedPages/ProductDetailPage/Resources/Resources.utils";
import { FileUploader } from "../util/FileUploader";
import useSWR from "swr";
import type {
  Language,
  OptionType,
  SupportedLanguage,
} from "../../../../../../types/types";
import { ErrorPlaceholder } from "../../../../../../components/Error";
import type { AssetCategory, AssetType } from "../util/AssetsUtil";

/**
 * This is used for type inference
 */
const AddAssetSchema = z.object({
  asset_name: z.string(),
  input_asset_type: zodSelectBoxType,
  input_category_type: zodSelectBoxType,
  input_lang_type: zodSelectBoxType.optional(),
  file_type: z.instanceof(File).optional(),
});

type FormValues = z.infer<typeof AddAssetSchema>;

/**
 *
 * This is used for schema validation
 */
const AddAssetSchemaFn = (t: (s: string) => string) =>
  z
    .object({
      asset_name: z
        .string()
        .regex(stringIsNotOnlyWhiteSpaceRegex, t("This is a required field")),
      input_asset_type: zodSelectBoxDefault(t),
      input_category_type: zodSelectBoxDefault(t),
      input_lang_type: zodSelectBoxDefault(t).optional(),
      file_type: z.instanceof(File).optional(),
    })
    .superRefine(({ input_asset_type, input_category_type }, ctx) => {
      if (!input_asset_type?.value || !input_category_type?.value) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: t("This is a required field"),
          path: ["input_asset_type"],
        });
      }
    });

const MarginBottomHeaderLeft = styled(HeaderLeft)`
  margin-bottom: 28px;
`;

export const EditAsset = ({
  onComplete,
  refreshAssetsList,
  assetData,
}: {
  onComplete: () => void;
  refreshAssetsList: any;
  assetData: Assets;
}) => {
  const { t } = useTranslation();

  const [submitting, setSubmitting] = useState(false);

  const { notifySuccess, notifyError } = useContext(Notifications);
  const { tenant_id, storefront_id } = useStoreState();

  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const [assetTypes, setAssetTypes] = useState<
    OptionType<SupportedAssetType>[]
  >([]);
  const [assetCategories, setAssetCategories] = useState<
    OptionType<SupportedAssetCategoryType>[]
  >([]);
  const [assetLanguages, setAssetLanguages] = useState<
    OptionType<SupportedLanguage>[]
  >([]);
  const { category, asset_type, language, id, name } = assetData;

  const methodsOfUseForm = useFormWrapper({
    resolver: zodResolver(AddAssetSchemaFn(t)),
    defaultValues: {
      input_asset_type: { label: toTitleCase(asset_type), value: asset_type },
      input_category_type: {
        label: category?.name,
        value: category?.id,
      },
      input_lang_type: language
        ? assetLanguages.find((item) => item.value === language)
        : undefined,
      asset_name: getNameAndExtension(name)?.name,
    },
  });
  const {
    handleSubmit,
    control,
    errors,
    formState,
    register,
    setValue,
    watch,
  } = methodsOfUseForm;

  const selectedAssetType: string = watch("input_asset_type")?.value;

  const { error: assetTypeError } = useSWR<AssetType>(
    endpoints.v2_storefronts_id_pim_asset_types(),
    {
      onSuccess: ({ asset_types }) =>
        setAssetTypes(
          asset_types.map((type) => ({
            label: toTitleCase(type),
            value: type,
          }))
        ),
    }
  );

  const { error: categoryTypeError } = useSWR<AssetCategory>(
    selectedAssetType
      ? `${endpoints.v2_tenants_id_pim_assets_categories(
          tenant_id
        )}?asset_type=${selectedAssetType}`
      : endpoints.v2_tenants_id_pim_assets_categories(tenant_id),
    {
      onSuccess: ({ asset_categories }) =>
        setAssetCategories(
          asset_categories.map(({ id, name }) => ({
            label: name,
            value: id,
          }))
        ),
    }
  );

  useSWR<{
    languages: Language[];
  }>(endpoints.v1_storefronts_id_languages(storefront_id), {
    onSuccess: (languages) => {
      setAssetLanguages(
        languages.languages.map((item) => ({
          label: `${item.name} (${item.alpha_2.toUpperCase()})`,
          value: item.alpha_2,
        }))
      );
      const selectLanguageOption = languages.languages.find(
        (item) => item.alpha_2.toLocaleUpperCase() === language
      );
      setValue(
        "input_lang_type",
        selectLanguageOption?.alpha_2
          ? {
              label: `${
                selectLanguageOption?.name
              } (${selectLanguageOption?.alpha_2.toLocaleUpperCase()})`,
              value: selectLanguageOption?.alpha_2,
            }
          : undefined
      );
    },
    onError: () =>
      notifyError(t("could not load asset languages, Something went wrong.")),
  });

  const onSubmit = async ({
    asset_name,
    input_asset_type,
    input_category_type,
    input_lang_type,
    file_type,
  }: FormValues): Promise<void> => {
    const paramsObj = {
      name: asset_name,
      asset_type: input_asset_type?.value.toLowerCase(),
      category_id: input_category_type?.value,
      language: input_lang_type?.value,
    };

    const baseURL = endpoints.v2_storefronts_id_pim_assets_id(tenant_id, id);

    setSubmitting(true);

    try {
      await Axios.patch(baseURL, paramsObj);

      if (file_type) {
        const formData = new FormData();
        formData.append("file", file_type);
        await Axios.put(baseURL, formData);
      }
      await refreshAssetsList();
      notifySuccess(t("Asset successfully edited"));
      onComplete();
    } catch (error) {
      if (
        isAxiosError(error) &&
        (error as AxiosError)?.response?.data?.message
      ) {
        notifyError(error?.response?.data?.message);
      } else {
        notifyError(t("could not edit asset, Something went wrong."));
      }
    } finally {
      setSubmitting(false);
    }
  };

  if (assetTypeError || categoryTypeError) {
    return (
      <ErrorPlaceholder
        message={t(
          "There was an error loading asset types and categories. Please try again later"
        )}
      />
    );
  }

  return (
    <>
      <MarginBottomHeaderLeft>
        <SectionTitle>{t("Edit Asset")}</SectionTitle>
      </MarginBottomHeaderLeft>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          as={SelectBoxV2}
          control={control}
          name="input_asset_type"
          autoComplete={"input_asset_type"}
          placeholder={t("Asset Type")}
          options={assetTypes}
          errors={errors}
          formState={formState}
          isDisabled={true}
        />
        <Controller
          as={SelectBoxV2}
          control={control}
          name="input_category_type"
          autoComplete={"input_category_type"}
          placeholder={t("Category")}
          options={assetCategories}
          errors={errors}
          formState={formState}
        />
        <FileUploader
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          setValue={setValue}
          selectedAssetType={selectedAssetType.toLowerCase()}
          control={control}
          errors={errors}
          formState={formState}
          editFileName={name}
        />
        <TextField
          name="asset_name"
          label={t("Asset Name")}
          autoComplete={"asset_name"}
          formState={formState}
          theref={register({
            required: false,
          })}
          errors={errors}
          type="text"
        />
        <Controller
          as={SelectBoxV2}
          control={control}
          name="input_lang_type"
          autoComplete={"input_lang_type"}
          placeholder={t("Language")}
          options={assetLanguages}
          errors={errors}
          formState={formState}
        />
        {/* <Controller
          as={SelectBoxV2}
          control={control}
          name="input_region_type"
          autoComplete={"input_type"}
          placeholder={t("Region")}
          options={regions}
          rules={{
            required: true,
          }}
          errors={errors}
          formState={formState}
        /> */}
        <PrimaryButtonFitContainer
          style={{ marginTop: "32px" }}
          type="submit"
          loading={submitting}
        >
          {t("Save")}
        </PrimaryButtonFitContainer>
      </Form>
    </>
  );
};
